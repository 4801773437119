<template>
    <section id="EditProfile">
        <Profile edit />
    </section>
</template>

<script>

    // Components.
    import Profile from '@/components/Profile.vue';

    export default {
        name: 'EditProfile',
        components: {
            Profile,
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
        }
    }

</script>

<style lang="scss">

    @import '../styles/_variables.scss';

    #EditProfile .card {
        margin: size(Medium) auto;
    }

    #EditProfile h3:first-of-type {
        margin-top: 0;
    }

    #EditProfile input:not(:first-of-type),
    #EditProfile .Button {
        margin-top: size(Small);
    }

</style>
